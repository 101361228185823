/**
 * Settings
 *
 * Core defaults
 */

/**
 * Breakpoints
 *
 * Hold our breakpoint aliases and conditions in a list. These can be invoked
 * later on via the `respond-to()` mixin found in _mixins.scss.
 */

$breakpoints: (
  "mobile" "(max-width: 745px)",
  "tablet" "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "tablet-down" "(max-width: 1023px)",
  "desktop" "(min-width: 1024px)",
  "desktop-plus" "(min-width: 1200px)",
  "wide" "(min-width: 1400px)",
  "max" "(min-width: 2000px)",
  "shallow" "(min-width: 1024px) and (max-height: 850px)",
  "iphone5"
    "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)",
  "portrait" "(orientation: portrait)",
  "landscape" "(orientation: landscape)"
);

/**
 * Base Grid & Spacing values
 */

$base-vw: 1024;
$base-vh: 768;

$base-logo-width: (
  "*": 130px,
  "mobile": 320px,
);
$base-player-width: (
  "*": 340px,
  "mobile": 340px,
);
$base-player-height: (
  "*": 60px,
  "mobile": 60px,
);
$base-interface-position: (
  "*": 40px,
  "mobile": 20px,
);
$base-header-height: (
  "*": 64px,
  "mobile": 48px,
);
$base-interface-height: (
  "*": 35px,
  "mobile": 33px,
);
$base-padding: (
  "*": 40px,
  "mobile": 30px,
);
$base-grid-gutter: (
  "*": 20px,
  "mobile": 10px,
);
$base-vw-gutter: (
  "*": 0.5vw,
  "mobile": 2vw,
);

$base-max-width: 1200px;
$base-max-text-width: 700px;
$base-max-dialog-width: 700px;

/**
 * Base Type Settings
 */

$base-font-size: 16px;
$base-line-height: 21px;
$base-letter-spacing: 0em;

$base-font-family: "RTM", sans-serif;
$base-font-family-bold: "RTM", sans-serif;
$base-font-family-alt: "RTM", sans-serif;

$base-text-align: center;

$base-text-families: (
  "sans": (
    "weights": (
      "regular": '"Helvetica", Arial, sans-serif',
      "bold": '"Helvetica", Arial, sans-serif',
    ),
    "sizes": (
      "small": $base-font-size * 0.75,
      "medium": $base-font-size,
      "large": $base-font-size * 1.5,
    ),
  ),
  "mono": (
    "weights": (
      "regular": monospace,
    ),
    "sizes": (
      "medium": $base-font-size,
      "small": $base-font-size * 0.75,
    ),
  ),
);

$base-text-applications: ();

$base-text-weights: (
  "*": "20px",
  "mobile": "10px",
);
$base-text-sizes: (
  "*": "20px",
  "mobile": "10px",
);

/**
 * Base Colours
 */

$grey: #655f52;
$light-grey: rgb(125, 125, 125);
$brown: rgb(32, 21, 14);
$beige: #dcd8bc;
$green: #d0d8ce;
$menuBg: white;
$brick: #9e4a20;

$rtm-blue: rgb(0, 0, 255);
$rtm-green: rgb(0, 148, 94);

$base-color: black;
$base-background: white;
$base-link-color: $base-color;
$base-link-opacity-hover: 1;
$base-active-color: $rtm-blue;

$base-overlay-background: #000;
$base-overlay-transparency: 0.85;

$base-ui-color: #ccc;
$base-ui-color-alt: #333;

$base-placeholder-color: $base-color;
$base-placeholder-opacity: 0.3;

$base-selection-background: rgba(0, 0, 0, 1);
$base-selection-color: #fff;

$base-image-color: #eee;
$base-image-color-loaded: #eee;
$base-image-fade: 2s;
$base-image-delay: 0.25s;
$base-image-ease: ease;

/**
 * Base Icons
 */

$base-icons: "arrow-right" "arrow-left";
$base-icon-size: 22px;
$base-icon-extension: "svg";

$base-icon-glyphs: (
  "arrow": "\e900",
  "play": "\e906",
  "pause": "\ea1d",
  "facebook": "\ea91",
  "instagram": "\ea92",
  "twitter": "\ea96",
  "rtm": "\e902",
  "clock": "\e908",
  "search": "\e907",
  "arrow-right": "\e903",
  "cross": "\e901",
  "volume": "\e909",
);

/**
 * Base Cursors
 */

$base-cursor-prev: pointer;
$base-cursor-next: pointer;
$base-cursor-play: pointer;
$base-cursor-pause: pointer;

/**
 * Base Sections (Sections being regions that allow you to control core styling)
 * The keys in this array will be used as modifiers (.Section--{key}) with the config settings provided
 * At a config level Sections can define:
  - background color
  - foreground color,
  - heading typeface,
  - body typeface
  - padding for the .Section-body
  - max width for the .Section-body
  - colour for images
  - colour links
  - colour for active items
 */

$base-sections: (
  "*": (
    "transition": all 0.4s ease,
    "background": transparent,
    "color": $base-color,
    "heading-font-family": $base-font-family,
    "text-font-family": $base-font-family,
    "container-padding": $base-padding,
    "container-max-width": $base-max-width,
    "image-color": $base-image-color,
    "link-color": $base-link-color,
    "active-color": $base-active-color,
  ),
  "modifier": (
    "background": $base-color,
    "color": $base-background,
    "heading-font-family": $base-font-family-alt,
    "text-font-family": $base-font-family,
    "container-padding": $base-padding,
    "container-max-width": $base-max-width,
    "image-color": $base-background,
    "link-color": $base-ui-color,
    "active-color": $base-active-color,
    "when-inside": (
      ".Site--modified": (
        "background": $base-color,
        "color": $base-background,
      ),
    ),
  ),
);

/**
 * Top level hooks
 */

$base-touch-class: ".Html--touch";
