@font-face {
    font-family: "Antique Olive Std";
    src: url("./antiqueolivestd-bold.woff2") format("woff2"),
        url("./antiqueolivestd-bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Antique Olive Nord";
    src: url("./antiqueolivestd-nord.woff2") format("woff2"),
        url("./antiqueolivestd-nord.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Antique Olive Std";
    src: url("./antiqueolivestd-light.woff2") format("woff2"),
        url("./antiqueolivestd-light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Antique Olive Std";
    src: url("./antiqueolivestd-roman.woff2") format("woff2"),
        url("./antiqueolivestd-roman.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
