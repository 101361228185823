.Blocks {
	@include responsive("padding-top", $base-header-height);

	.Site-main & {
		color: white;
	}

	.Site-panel & {
		padding-top: 0 !important;
	}
	// Included templates within blocks hide their heading & body

	.Template {
		min-height: 0;
	}
	.Template-content {
		display: none;
	}
}
.Block-wrapper {
	display: block;
}
.Block {
	//border-top:2px solid;

	.Block-heading {
		@extend %u-text--heading;
		@include responsive-x(1, "padding-top", $base-interface-height);
		@include responsive-x(1, "padding-left", $base-interface-height);
		@include responsive-x(1, "padding-right", $base-interface-height);
	}

	.Block-callToAction {
		@include responsive-x(1, "padding-left", $base-padding);
		@include responsive-x(1, "padding-right", $base-padding);
		@include responsive-x(1, "padding-bottom", $base-interface-height);

		&.Block-callToAction--after {
			@include responsive-x(1, "padding-top", $base-interface-height);
		}
		.Link--button {
			color: inherit;
		}
	}

	&.Block--align-centre {
		.Block-heading,
		.Block-callToAction,
		.Content,
		.Block-items,
		.Grid--links {
			text-align: left;
		}

		.Block-links > *,
		.Block-heading > *,
		.Block-content .Content > * {
			max-width: 600px;
			margin: 0 auto;
		}

		.Block-content .Content ul,
		.Block-content .Content ol {
			text-align: left;
		}
	}

	&.Block--align-right {
		.Block-heading,
		.Block-callToAction,
		.Content,
		.Block-items,
		.Grid--links {
			text-align: left;
			//text-align:left;
			/*margin-left:auto;
			max-width:600px;
			box-sizing:content-box;

			& > *{
				box-sizing:border-box;;
			}*/
		}

		.Grid--links {
			text-align: left;
		}

		.Block-links > *,
		.Block-heading > *,
		.Block-content .Content > * {
			margin-right: 0;
			margin-left: auto;
			text-align: left;
			max-width: 600px;
		}

		.Content ul,
		.Content ol {
			//direction:rtl;
		}
	}
}
.Block-media,
.Block-item {
	position: relative;
}

.Blocks-section {
	// Stop margins going outside div
	&:after {
		content: "";
		display: table;
	}

	&:first-child {
		.Block-heading {
			@include responsive-x(2, "padding-top", $base-interface-height);
		}

		.Block--titleCard .Block-heading {
			padding-top: 10px;
		}

		.Block--text .Block-content .Content:first-child {
			@include responsive-x(1, "padding-top", $base-interface-height);
		}

		.Site-panel & {
			padding-top: 0 !important;
		}
		&:last-child {
			min-height: 100vh;
		}
	}
}
.Blocks-section--none,
.Blocks-section--dark {
	color: $base-background;
	a {
		color: $base-background;
	}
}

.Blocks-section--light {
	color: $base-color;
	a {
		color: inherit;
	}
}

.Blocks-section--alt {
	background: white;
	color: $base-color;
	a {
		color: $base-color;
	}
}

/*

Items 

*/

.Block--default {
	.Block-content {
		@include responsive("padding-left", $base-padding);
		@include responsive("padding-right", $base-padding);
		padding-bottom: 2rem;
		padding-top: 1rem;
	}
}
.Block--scroller {
	.Block-content {
		@include responsive("padding-left", $base-padding);
		@include responsive("padding-right", $base-padding);
		padding-bottom: 2rem;
		padding-top: 1rem;
	}
}

.Block--default .Block-links,
.Block--scroller .Block-links {
	@include responsive("padding-left", $base-padding);
	@include responsive("padding-right", $base-padding);
	@include responsive("padding-bottom", $base-padding);

	.Grid-item {
		display: inline-block;
		width: auto;

		a {
			@extend %u-link--button;
		}
	}
}

/*

Ticker 

*/

@keyframes scrollLeft {
	from {
		left: translateX(0%);
	}
	to {
		transform: translateX(-25%);
	}
}

@keyframes scrollRight {
	from {
		transform: translateX(-25%);
	}
	to {
		transform: translateX(0%);
	}
}

.Block.Block--ticker {
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	width: 100%;
	position: relative;
	white-space: nowrap;

	@include responsive-x(0.8, "height", $base-interface-height);

	ul {
		white-space: nowrap;
		width: auto;
		display: inline-block;
		overflow: hidden;

		animation: scrollLeft 120s infinite;
		animation-timing-function: linear;
	}

	&:hover {
		ul {
			animation-play-state: paused;
		}
	}
	ul li {
		display: inline-block;
		@include responsive-x(1.4, "height", $base-interface-height);
		@include responsive-x(1.1, "line-height", $base-interface-height);
		@extend %u-ticker--small;
		// 

		&:nth-child(odd) {
			@extend %u-ticker--light;
		}

		&:before {
			content: "•";
			display: inline-block;
			padding: 0 20px;
			font-style: normal;
		}
	}
}

/*

Play

*/

.Block--play {
	@include responsive("padding", $base-interface-height);
	text-align: center;
}

/*

Coverflow

*/

.Block-coverflow {
	@include responsive("padding-top", $base-interface-height);
	@include responsive-x(1, "padding-bottom", $base-interface-height);

	.Block-content {
		@include responsive("padding-left", $base-padding);
		@include responsive("padding-right", $base-padding);
		padding-bottom: 2rem;
	}
	.swiper-slide {
		width: 50vw;
		@include respond-to("tablet") {
			width: 30vw;
		}
		@include respond-to("desktop") {
			width: 20vw;
		}

		* {
			pointer-events: none;
		}
	}

	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right {
		height: auto !important;
		&:after {
			content: "";
			display: block;
			@include pad-ratio(6, 6);
		}
	}
	.swiper-container {
	}

	.swiper-pagination,
	.swiper-scrollbar,
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.Block-swiper {
		@include responsive("padding-bottom", $base-padding);
	}

	.Item--coverflow {
		cursor: inherit;
	}

	.swiper-slide-active .Item--coverflow {
		a,
		[data-href],
		.Playbutton {
			pointer-events: all;
			cursor: pointer;
		}
	}
}

/*

Masthead

*/

@keyframes growDown {
	0% {
		height: 0;
	}

	100% {
		height: 40%;
	}
}

.Block--masthead {
	position: relative;
	height: 100vh;
	overflow: hidden;

	.Block-screen {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 0;

		.Block-logo {
			svg rect,
			svg path,
			svg polygon {
				fill: $base-background;
			}

			position: absolute;

			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
			width: 70%;
			max-width: 600px;
			opacity: 0.3;
			& > div {
				animation: fadeOut 2s 1s ease forwards;
			}
		}

		animation: fadeOut 3s 1.2s ease forwards;
	}

	.Block-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		clip: rect(0, auto, auto, 0);
		overflow: hidden;

		h1 {
			position: fixed;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
			text-align: center;
			@extend %u-text--heading;
			color: $base-background;
			z-index: 1;
			//text-shadow:0 0 3px rgba(0,0,0,0.6);
		}

		&:after {
			content: "";
			width: 4px;
			display: block;
			position: fixed;
			top: 60%;
			height: 0;
			left: 50%;
			//background:url('/ui/img/textures/vertical-creme.png') top center no-repeat;
			background-size: 100% 50vh;
			transform: translateX(-50%);
			z-index: 1;
			animation: growDown 3s 1.5s ease forwards;
		}
	}
	.Block-media {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
		z-index: 0;
		backface-visibility: hidden;
		//opacity:0.6;
	}

	[data-progress-marker] {
		height: 100%;
	}
}

/*

Divider

*/

.Block--divider {
	&:after {
		content: "";
		display: block;
		border-bottom: 1px solid;
	}
	opacity: 0.2;
	//@include responsive-x(1,'padding-top',$base-padding);
	//@include responsive-x(1,'padding-bottom',$base-padding);
}

/*

Item

*/

.Block--item {
	.Block-head {
		@include responsive("padding", $base-padding);
		@include responsive-x(1, "padding-top", $base-padding);
		text-align: center;
	}

	@include responsive-x(2, "padding-bottom", $base-padding);

	.Block-media,
	.Block-content {
		display: inline-block;
		vertical-align: middle;
		width: 100%;

		@include respond-to("tablet-up") {
			width: 50%;
		}
	}
	.Block-content {
		text-align: center;

		p {
			max-width: none;
		}

		.Content {
			max-width: 500px;
			margin: 0 auto;

			& > :last-child {
				padding-bottom: 0;
			}
		}

		@include responsive("padding", $base-padding);
	}

	&.Block--inset {
		.Block-media {
			@include responsive-x(2, "padding-left", $base-padding);
			@include responsive-x(2, "padding-right", $base-padding);

			.Image {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
			}
		}
	}
	&.Block--imageRight {
		direction: rtl;
		.Block-head,
		.Block-content {
			direction: ltr;
		}
	}
}

/*

Text

*/

.Block--text {
	.Block-head {
		@include responsive("padding", $base-padding);
		@include responsive-x(2, "padding-top", $base-padding);
		text-align: center;

		h1 {
			padding-bottom: 0;
		}
	}

	@include responsive("padding-left", $base-padding);
	@include responsive("padding-right", $base-padding);
	@include responsive("padding-bottom", $base-padding);

	.Blocks-section:first-child & {
		&:first-child {
			@include responsive("padding-top", $base-padding);
		}
	}

	.Blocks--article & {
		@include respond-to("tablet-up") {
			padding-left: 10vw;
			padding-right: 10vw;

			.Content {
				width: 60%;
				margin-left: auto;
				margin-right: auto;
			}

			&.Block--right {
				.Content {
					width: 50%;
					margin-left: auto;
					margin-right: 0;
				}
			}

			&.Block--left {
				.Content {
					width: 50%;
					margin-right: auto;
					margin-left: 0;
				}
			}
		}
	}
}

/*

Schedule

*/

.Block--radiocoSchedule {
	@include responsive("padding-bottom", $base-padding);
}

/*

Widget

*/

.Block--eventbriteWidgetButton {
	@include responsive("padding", $base-padding);
	@include responsive("padding-top", 0);

	&.Block--align-centre {
		text-align: center;
	}

	&.Block--align-right {
		text-align: right;
	}
}

/*

Image

*/

.Block--image {
	@include responsive("padding-bottom", $base-padding);
	@include responsive("padding-top", $base-padding);
	@include respond-to("tablet-up") {
		@include responsive("margin-bottom", $base-grid-gutter);
	}

	&.Block--half {
		@include responsive("padding-left", $base-padding);
		@include responsive("padding-right", $base-padding);

		@include respond-to("tablet-up") {
			width: 50%;
			vertical-align: top;
			display: inline-block;
		}
	}
	.Grid-item {
		@include responsive("padding-bottom", $base-grid-gutter);

		&:last-child {
			padding-bottom: 0 !important;
		}

		&[data-offset="offset"] {
			@include responsive-x(2, "padding-top", $base-padding);
		}
	}

	.Block-content {
	}

	.Grid {
		text-align: center;

		.Content {
			text-align: left;
		}
	}

	.Grid--full {
		.Content {
			@include responsive("padding-top", $base-grid-gutter);
			@include responsive("padding-bottom", $base-grid-gutter);
		}
	}

	.Grid--half {
		.Grid-item {
			width: 50%;
		}

		.Content {
			@include responsive("padding", $base-grid-gutter);
		}
	}

	@include respond-to("tablet-up") {
		@include responsive("padding-left", $base-padding);
		@include responsive("padding-right", $base-padding);
	}
}

/*

Form

*/

.Block--form {
	@include responsive("padding-left", $base-padding);
	@include responsive("padding-right", $base-padding);

	.Site-footer & {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

/*

Mosaic

*/

.Block--mosaic {
	@include responsive("padding-top", $base-grid-gutter);
	@include responsive-x(2, "padding-bottom", $base-grid-gutter);
	@extend %u-cf;

	.Block-section {
		@extend %u-grid;

		@include respond-to("tablet-up") {
			@include responsive("margin-bottom", $base-grid-gutter);
		}
	}

	.Block-item {
		@extend %u-gridItem;
		@include respond-to("mobile") {
			float: left;
			@include responsive("padding-bottom", $base-grid-gutter);
		}
		width: 50%;

		@include respond-to("tablet-up") {
			padding-bottom: 0;

			&.Block-item--twothirds {
				width: 2/3 * 100%;
			}

			&.Block-item--third {
				width: 1/3 * 100%;
			}
		}
	}

	@include respond-to("tablet-up") {
		@include responsive("padding", $base-padding);
	}
}

/*

Swiper

*/

.Block--swiper {
	.Block-content {
		@include responsive("padding-left", $base-interface-height);
		@include responsive("padding-right", $base-interface-height);
		padding-bottom: 2rem;
		padding-top: 1rem;
	}
	.swiper-slide {
		width: auto;
	}

	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right {
		height: auto !important;
		&:after {
			content: "";
			display: block;
			@include pad-ratio(5, 6);
		}
	}

	[data-swiper-slide-index] {
		//border: 5px solid transparent;
		.Item-media {
			//box-shadow:0 0 5px rgba(0,0,0,0.2);
		}
	}
	.swiper-container {
	}

	.swiper-pagination {
		bottom: 0;
		right: 0;
		left: auto;
		width: 50%;
		@include responsive("height", $base-interface-height);
		@include responsive("padding-right", $base-interface-height);
		@include responsive-x(1.2, "line-height", $base-interface-height);
		text-align: right;
		@extend %u-text--small;

		.swiper-pagination-bullet-active,
		.swiper-pagination-bullet {
			background: white;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none;
	}

	.swiper-scrollbar {
		display: none;
	}

	.Item {
		cursor: inherit;
		opacity: 0.7;
		transition: opacity 0.6s ease;
	}

	.swiper-slide-active .Item {
		&[data-href=""] {
			cursor: inherit;
		}
		opacity: 1;
	}
}

/*

Gallery

*/

.Block--gallery {
	@include responsive("padding", $base-padding);
	.Block-media {
		margin: 0 auto;
		max-width: $base-max-width;
	}
}

/*

Title Card

*/

.Block--titleCard {
	position: relative;
	height: 50vh;
	background: black;
	text-align: center;

	.Block-media {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		opacity: 0.6;
	}
	.Block-content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		z-index: 1;
		color: white;
		padding: 60px;
	}
	&[style] {
		.Block-content {
			color: inherit;
		}
	}
	.Block-heading {
		@extend %u-text--headingBold;
		padding-top: 10px;
		padding-bottom: 0;
		padding-bottom: 5px;
	}

	.Block-preHeader {
		padding-left: 10px;
		font-weight: 600;
		@extend %u-text--body;
	}
	.Block-postHeader {
		@extend %u-text--body;
	}
}

/*

Spacer

*/

.Block--spacer {
	&[data-spaces="1"] {
		@include responsive-x(1, "height", $base-interface-height);
	}

	&[data-spaces="2"] {
		@include responsive-x(2, "height", $base-interface-height);
	}

	&[data-spaces="3"] {
		@include responsive-x(3, "height", $base-interface-height);
	}

	&[data-spaces="4"] {
		@include responsive-x(4, "height", $base-interface-height);
	}

	&[data-spaces="5"] {
		@include responsive-x(5, "height", $base-interface-height);
	}
}

/*

Embed

*/

.Block--embed {
	.Block-iframe {
		position: relative;
		margin: 0 auto;
		width: 80%;
		max-width: 1200px;

		border: 2px solid;
		box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:after {
			content: "";
			display: block;
			height: 0;
			width: 100%;
		}
	}

	&.Block--audio {
		.Block-iframe {
			&:after {
				height: 60px;
			}
		}
	}

	&.Block--video {
		.Block-iframe {
			&:after {
				padding-top: 56%;
			}
		}
	}
}

/*

Arrangement

*/

.Block--arrangement {
	@include responsive("padding-top", $base-padding);

	.Block-scroll {
		height: 60vh;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;

		&:after {
			content: "";
			display: inline-block;
			@include responsive("width", $base-padding);
		}

		@include responsive("padding-left", $base-padding);

		@include respond-to("tablet") {
			//padding-left:20vw;
		}
	}
	.Block-section {
		display: inline-block;
		width: auto;
	}

	.Block-content {
		@include responsive("padding", $base-padding);
	}
	.Block-media-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 5px;
		bottom: 5px;
		@include responsive("bottom", $base-grid-gutter);
		@include responsive("right", $base-grid-gutter);
	}

	@include respond-to("desktop") {
		position: relative;

		.Block-content {
			padding-top: 30vh;
			padding-bottom: 30vh;
			margin-right: 50%;
			min-height: 100vh;
		}
		.Block-scroll-wrap {
			position: absolute;
			top: 40px;
			left: 0;
			right: 0;
			bottom: 0;
			left: 50%;
			& > * {
				position: sticky;
				height: calc(60vh + 40px);
				width: 100%;
				top: 20vh;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}
}

/*

Text With Image

*/

.Block--textWithImage {
	.Block-content {
		@include responsive("padding", $base-padding);
	}
	.Block-media {
		/* Inset:;
		@include responsive-x(2,'padding-top',$base-padding);
		@include responsive-x(2,'padding-left',$base-padding);
		@include responsive-x(2,'padding-right',$base-padding);
		*/
	}

	@include respond-to("desktop") {
		position: relative;

		.Block-content {
			@include responsive-x(2, "padding-top", $base-padding);
			margin-left: 50%;
			min-height: 100vh;
		}
		.Block-media {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 50%;
			& > * {
				position: sticky;
				height: 100vh;
				width: 100%;
				top: 0;
				left: 0;

				.Image {
					padding: 0 !important;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}
	}
}

/*

Newsletter

*/

.Block--newsletter {
	.Block-content {
		margin: 0 auto;
		max-width: $base-max-width;
	}
}
