html{
	background:black;
	&:after{
		content:'Please Wait...';
		@extend %u-text--smallcaps;
		@include responsive('line-height',$base-interface-height);
		@include responsive('padding-bottom',$base-interface-height);
		@include responsive('padding-left',$base-interface-height);
		display:block;
		position:fixed;
		bottom:0;
		left:0;
		color:white;
		z-index:99999;
		pointer-events:none;
		transition:opacity 0.4s 1s ease;

	}

	&.Html--loading,
	&.Html--loaded{
		&:after{
			opacity:0;
		}
	}

	&.Html--loaded{
		background:$base-background;
	}
}
.Loader{
	display:none;
}

*{
	//border:1px solid red;
}

/*

Interface defaults

*/
[data-close]{

	@extend %u-text--body;
	position:absolute;
	z-index:1;
	@include responsive('top',$base-interface-position);
	@include responsive('right',$base-interface-position);
}
[data-scroll-pane]{

	overflow:hidden;
	overflow-y:auto;
	-webkit-overflow-scrolling:touch;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:0;
}

/*

Loader

*/
.Site-loader{
	
	@extend %u-text--body;
	
	position:fixed;
	left:0;
	pointer-events:none;
	@include responsive-x(1,'top',$base-interface-height);	
	@include responsive-x(1,'height',$base-interface-height);	
	@include responsive-x(1,'width',$base-logo-width);
	white-space:nowrap;

	z-index:10;
	color:white;
	transition:opacity 0.6s 0.6s ease;

	.Html--loaded &{

		opacity:0;
	}
}

/*

Burger

*/
.Site-burger{
	
	@extend %u-text--smallcaps;
	@include responsive-x(1.1,'line-height',$base-interface-height);
	position:fixed;

	@include responsive-x(2,'height',$base-interface-height);
	@include responsive-x(2,'width',$base-interface-height);

	@include respond-to('mobile'){
		@include responsive-x(1.5,'bottom',$base-interface-position);
		@include responsive-x(0.5,'right',$base-interface-position);
	}

	@include respond-to('tablet-up'){
		top:5px;
		right:5%;
	}
	box-shadow:1px 1px 1px rgba(0,0,0,0.5);

	color:white;

	border-radius:50%;
	z-index:6;
	cursor:pointer;
	background:$rtm-green;
	transform:rotate(12deg);
	transition:opacity 0.6s ease, background 0.6s ease;

	.Site--menu &{

		background-color:$rtm-green;

	}
	.Site--project &,
	.Site--article &{

		opacity:0;

	}

	.Burger{
		position:absolute;
		top:50%;
		left:50%;
		width:100%;
		transform:translate(-50%,-48%);
		&:before,&:after{
			line-height:2;
			transition:color 0.4s ease, opacity 0.4s ease, border 0.4s ease;
			display:block;
			border:none;
			display:block;
			width:100%;
			text-align:center;

		}
		&:before{
			
			content:'Menu';

			
		}
		&:after{
			content:'Close';
		
			position:absolute;
			top:0;
			left:0;
			opacity:0;
			width:100%;
			text-align:center;

		}

		.Site--menu &{

			&:before{

				opacity:0;


			}

			&:after{

				opacity:1;

			}

		}

	}

	.Html--no-touch &{
		&:hover{

			.Burger{

				&:before,&:after{

					

				}

			}

		}
	}

	@include respond-to('tablet-up'){

		display:none;
	}
}

/*

Header Nav

*/

.Site-header-nav{
	@include respond-to('mobile'){
		display:none;
	}
	height: 64px;

	@include respond-to('tablet-up'){
		display:block;
		position:fixed;
		top:0;
		right:0;
		text-align:right;
		z-index:5;
	}

}

/*
.Site-header-nav{
	text-align:right;

	& > *{
		display:inline-block;
		text-align:left;
		vertical-align:middle;
	}
	@extend %u-text--body;
	position:fixed;

	@include responsive-x(0.75,'top',$base-interface-position);
	@include responsive-x(1,'right',$base-interface-position);
	z-index:4;

	.Icon:before{
		color:$base-color;

		.Site--dark &{
			color:$base-background;
		}
	}

}
*/

/*

Shortcuts Nav

*/
/*
.Site-shortcuts-nav{
	
	@extend %u-text--body;
	position:fixed;

	@include responsive-x(0.75,'top',$base-interface-position);
	@include responsive-x(2,'left',$base-interface-position);

	z-index:5;


}*/

/*

Logo

*/

.Site-header{
	position:fixed;
	top:0;
	left:0;
	z-index:3;

	a {

		display:block;
		padding-top: 4px;
		padding-left: 4px;
		
		@include responsive-x(1,'width',$base-logo-width);	

		@include respond-to('mobile'){
			width: 92px
		}
	}

	
	@include responsive-x(1,'height',$base-header-height);	

	right:0;
	background:rgba($menuBg,0.9);
	backdrop-filter:blur(10px);

	transition:opacity 0.6s ease;

	.Site--scrolled &{
		//opacity:0;
	}

	svg path,
	svg plygon{
		fill:$base-color;
	}

}

/*

Live

*/

.Site-live{

	position:fixed;
	@include responsive-x(1,'top',$base-header-height);	
	@include responsive-x(1,'height',$base-interface-height);	
	@include responsive-x(1,'width',$base-player-width);	

	left:0;
	z-index:3;
	a{

		display:block;
		
		
	}


	background:#FFF;
	opacity:0.9;

	transition:opacity 0.6s ease;

	.Site--scrolled &{
		//opacity:0;
	}

	svg path,
	svg plygon{
		fill:$base-color;
	}

}

/*

Player

*/

.Site-player{

	position:fixed;
	bottom:0;	
	@include responsive-x(1,'height',$base-player-height);	
	width:100%;
	left:0;
	z-index:3;
	transform:translateY(200%);

	@include respond-to('tablet-down'){
		z-index:9;
	}

	a{

		display:block;
		
		
	}


	background:white;
	transition:transform 0.6s ease;

	.Site--player &{

		transform:none;

	}
	box-shadow:0 -1px 2px rgba(0,0,0,0.1);
	
}

/*

Ticker

*/

.Site-ticker{

	position:fixed;
	bottom:0;
	overflow:hidden;	
	@include responsive-x(1,'height',$base-interface-height);	
	width:100%;
	left:0;
	z-index:1;



	background:white;
	.Blocks{
		color:black;
	}
	box-shadow:0 -1px 2px rgba(0,0,0,0.1);
	
	.Blocks,.Blocks-section{
		padding-top:0!important;
	}
}

/*

Menu

*/

.Site-menu{

	position:fixed;
	top:0;
	
	bottom:0;
	
	left:50%;
	width:100%;
	max-width:600px;

	z-index:5;
	background:rgba($menuBg,0.6);
	backdrop-filter:blur(10px);
	pointer-events:none;
	opacity:1;
	transform:translate3d(-50%,-100%,0);
	transition:transform 0.6s ease;

	.List--nav{

		position:absolute;
		width:100%;
		top:50%;
		left:0;
		transform:translateY(-50%);

	}


	.Site--menu &{
		transition:transform 1.2s $easeOutExpo;
		transform:translate3d(-50%,0,0);
		pointer-events:all;

	}

	@include respond-to('tablet-up'){

		display:none;
	}

}

/*

Overlay

*/

.Site-overlay{

	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:2;
	pointer-events:none;
	*{pointer-events:none;}
	opacity:0;
	transition:opacity 0.6s ease;
	backface-visibility:hidden;


	.Site-overlay-content{

		background:black;
		color:white;
		min-height:100vh;
		position:relative;
		&:after{
			content:'';
			display:block;
			border-bottom:1px solid;
			opacity:0.2;
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
		}


		.swiper-slide{
			width:auto;
		}
	}

	.swiper-pagination,
	.swiper-button-next,
	.swiper-button-prev{
		display:none;
	}

	[data-screen]{
		height:100vh;
		background:$menuBg;
		background:black;
	}
	.Site--overlay &{
		transition:opacity 0.6s ease;
		opacity:1;
		pointer-events:all;
		*{pointer-events:inherit;}

	}

}

/*

Panel

*/

.Site-panel{

	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:7;
	background:$menuBg;
	pointer-events:none;
	opacity:1;
	transform:translate3d(100%,0,0);
	transition:transform 0.6s ease;
	backface-visibility:hidden;

	@include respond-to('tablet'){
		left:33%;
	}

	@include respond-to('desktop'){
		left:50%;
	}

	.Blocks-section:first-child{
		min-height:100vh;
		.Block:first-child{
			@include responsive-x(2,'padding-top',$base-padding);
		}
	}

	.Site-panel-close{

		@extend %u-text--smallcaps;
		text-align:center;
		@include responsive-x(2,'line-height',$base-interface-height);
		position:absolute;

		@include responsive-x(2,'height',$base-interface-height);
		@include responsive-x(2,'width',$base-interface-height);

		@include respond-to('mobile'){
			@include responsive-x(0,'top',$base-interface-position);
			@include responsive-x(0,'right',$base-interface-position);
		}

		@include respond-to('tablet-up'){
			top:5px;
			right:5%;
		}
		box-shadow:1px 1px 1px rgba(0,0,0,0.5);

		color:white;

		border-radius:50%;
		z-index:6;
		cursor:pointer;
		background: red;
		transform:rotate(12deg);
		transition:transform 0.2s ease;
		cursor:pointer;

		.Html--no-touch &{
			&:hover{
				opacity:1;
				transform:rotate(-10deg);
			}
		}

		&:hover {
			color: white;
		}

	}
	.Site--panel &{
		transition:transform 1.2s $easeOutExpo;
		transform:translate3d(0,0,0);
		pointer-events:all;

	}

	.Site-panel-screen{

		position:absolute;
		top:0;
		left:auto;
		right:100%;
		width:100%;
		height:100%;
		display:none;
	
		z-index:7;
		@include respond-to('desktop'){
			.Site--panel &{
				display:block;
			}
		}

	}

}

/*

Article

*/

.Site-article{

	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:5;
	pointer-events:none;
	opacity:0;
	transition:opacity 0.6s ease;
	backface-visibility:hidden;

	.Site-article-content{

		background:$menuBg;
		min-height:100vh;

	}

	[data-screen]{
		height:100vh;
		background:$menuBg;
		background:black;
	}
	.Site--article &{
		transition:opacity 0.6s ease;
		opacity:1;
		pointer-events:all;

	}

	.Site-article-head{
		@include responsive-x(2,'padding-top',$base-padding);
		@include responsive('padding-left',$base-padding);
		@include responsive('padding-right',$base-padding);
		@include responsive('padding-bottom',$base-padding);
		opacity:0.5;

		@include respond-to('tablet-up'){

			padding-left:10vw;

		}
	}
	.Site-article-close{

		padding:0 0.5em;
		padding-top:2px;
		line-height:150%;
		border-radius:2em;

	}

}

.Site-main{
	min-height:100vh;
	background:black;
	backface-visibility:hidden;
	box-shadow:0 2px 2px rgba(0,0,0,0.1);
}

/*

Footer

*/

.Site-footer-screen{

	pointer-events:none;
	position:relative;

	[data-progress-marker]{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}
	[data-screen]{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		@include respond-to('mobile'){
			//height:80vh;
		}
		background:black;
	}

}



.Site-footer{
	
	@include responsive('padding',$base-interface-height);
	// @include responsive-x(3,'padding-bottom',$base-interface-height);
	// min-height: 280px;

	@include respond-to('mobile'){
		min-height: 360px;
	}
	.Content p{
		max-width:none;
	}	

	position:fixed;
	bottom:0;
	left:0;
	right:0;
	background:white;
	z-index:-1;
}

.Site-footer-upper {
	display: flex;
	justify-content: space-between;
}

.Site-footer-nav{
	@include responsive-x(1,'padding-bottom',$base-interface-height);
	display: flex;
	flex-direction: column;
	text-align: right;

	@include respond-to('mobile'){
		margin-top: 180px;
	}
}
.Site-footer-content{
	@include respond-to('tablet-up'){
		display: none;
		width:80%;
		vertical-align:top;
		position:relative;
	}
}
.Site-footer-taco {
	margin-bottom: 16px;
	@extend %u-text--small;
	img{
		width:60px;
	}
	@include respond-to('tablet-up'){
		display:inline-block;
		text-align:right;
		vertical-align:top;
		// @include responsive('padding-top',$base-interface-height);
	}
	// @include respond-to('mobile'){
	// 	position:absolute;
	// 	bottom:20px;
	// 	left:0;
	// 	@include responsive('padding',$base-interface-height);
	// 	margin-bottom:-7px;
	// 	.Site--player &{
	// 		bottom:30px;
	// 	}
	// }
}
.Site-footer-address {
	line-height: 12px;

	p {
		font-size: 12px;
	}
}


.Site-footer-credit {
	opacity:0.6;
	bottom:20px;
	margin-bottom: 16px;

		.Site--player &{
			bottom:30px;
		}

		right:0;
		width: 100%;
		text-align:right;
		@extend %u-text--small;

		& > div{
		display:inline-block;
		padding-left:10px;
		}

		@include respond-to('mobile'){
			text-align: left;
			margin-left: -20px;
		}
}

.Site-search{

	position:fixed;
	@include responsive('height',$base-header-height);
	@include responsive-x(0,'top',$base-header-height);
	@include responsive-x(0,'right',$base-header-height);

	z-index:5;

	@include respond-to('tablet-up'){
		z-index:6;
	}
	pointer-events:none;

	padding:0;

	.Search {
	@include responsive('height',$base-header-height);
	}


}
