%u-link--button{
	cursor:pointer;
	@extend %u-text--smallcaps;
	padding:0 1em;
	border-radius: 2em;
	@include responsive('height',$base-interface-height);
	 @include responsive-x(0.95,'line-height',$base-interface-height);
	 border:0.2em solid;
	 display:inline-block;
	 padding-bottom:1px;
	 overflow:hidden;
	 text-overflow:ellipsis;
}

%u-link--buttonSolid{
	background:white;
	 	color:black;
	 	border-color:white;
}

