.Grid--gallery{

	@extend %u-grid;

	& > div{
		@extend %u-gridItem;
		width:1/2 * 100%;
		@include responsive('padding-bottom',$base-grid-gutter);
		@include respond-to('tablet-up'){
			width:1/4 * 100%;
		}
	}
}