.Search{
	position:relative;
	color:white;

	width:100vw;

	@include respond-to('tablet'){
		width:calc(100vw - 350px);
	}

	@include respond-to('desktop'){
		width:33vw;
	}

	.Search-body {
		@include responsive-x(1,'height',$base-header-height);
	}

	.react-autosuggest__input {
  		@include responsive-x(1,'width',$base-interface-height);
  		display:block;
  		height: 30px;
  		padding: 10px;
  		border-radius: 0;
  		transition:width 0.4s ease;
  		margin-left:auto;
  		background:#111;
  		color:white;
  		cursor:pointer;
  		pointer-events:all;
  		font-size:16px;
  		@include responsive-x(1,'height',$base-header-height);
  		@include responsive-x(1,'line-height',$base-interface-height);
  		&::placeholder{
  			color:white;
  			opacity:0;
  			transition:opacity 0.4s ease;
  		}
	}

	&.Search--active{
		.react-autosuggest__input{
			width:100%;
			cursor:inherit;
			&::placeholder{
				opacity:0.7;
			}
		}

		.react-autosuggest__input--focused {
		
			&::placeholder{
  				opacity:0.2;
  			}
		}
	}

	.react-autosuggest__suggestions-container{
		transition:opacity 0.4s ease;
		opacity:0;
	}

	.react-autosuggest__suggestions-container--open{
		background:#111;
		backdrop-filter:blur(10px);
		color:white;
		padding:10px 0;
		border-radius:0px;
		pointer-events:all;
		max-height:90vh;
		overflow-y:auto;
		-webkit-overflow-scrolling:touch;
		margin:0;
		
		box-shadow:-2px 2px 2px rgba(0,0,0,0.5);
		padding-bottom:0;
		opacity:1;
		
	}

	.react-autosuggest__section-title {
	  padding: 2px 10px;
	  font-size: 11px;
	  margin:5px;
	  background:#333;
	  opacity:0.4;
	  border-radius:5px;
	}

	.react-autosuggest__suggestion {
  		cursor: pointer;
  		padding: 5px ;

	}

	.react-autosuggest__suggestion--highlighted {
  		background-color:rgba(255,255,255,0.05);
	}
}

.Search-icon,
.Search-close{
	position:absolute;
	@include responsive('width',$base-interface-height);
	@include responsive('height',$base-header-height);
	top:0;
	right:0;
	background:#111;
	cursor:pointer;
	svg{
		width:60%;
		height:60%;
		position:absolute;
		top:50%;
		left:50%;
		transform: translate(-55%,-55%);
		
	}

	z-index:2;
	display:none;

}

.Search--inactive{

	.Search-icon{
		display:block;
		pointer-events:all;
	}

}


.Search--active{

	.Search-close{
		display:block;
		pointer-events:all;
	}

}