.List--nav{
  li{
    @extend %u-text--body;
  }
}

.List--tabs{
  color:white;

  background:#222;
  overflow:hidden;
  li{
    @extend %u-text--body;
    padding:20px;
    display:inline-block;
    cursor:pointer;
    border-bottom:2px solid transparent;
    &.active{
      background:rgba(255,255,255,0.2);
      border-bottom:2px solid white;
    }

    &:hover{
      background:rgba(255,255,255,0.1);
 
    }
  }
}

.List--variants{

  li{
    @extend %u-text--body;
    padding:10px;
   display:inline-block;
  }
}

.List--nav.List--journal{
  @extend %u-text--body;
  line-height:1;
  li{

    display:inline-block;
    @include responsive('padding-left',$base-grid-gutter);

      a{
       line-height:1.3;
       display:inline-block;
       transition:border 0.4s ease;
        border-bottom:1px solid transparent;

      .Html--no-touch &{
        &:hover{
              opacity:1;
              border-bottom:1px solid;
          }

        }

    }

  }

}
.List--partners{
  

  &:before{

    content:'Supported by';
    @extend %u-text--smallcaps;
    display:block;
    padding-bottom:10px;
  }

  li{
    display:inline-block;
    width:1/5 * 100%;
    max-width:150px;
    vertical-align:top;
    margin-right:10px;
    @include respond-to('mobile'){
      width:1/3 * 100%;
    }
    .Image{
      background:transparent;
      //padding-top:40%!important;

      .Image-media{
        background-position:center left;
        background-size:contain;
      }
    }
  }
}
.List--nav.List--main{
  
  & > li{

      display:block;
      margin:0 10px;
      text-align:center;

      padding:1.5vh 0;
      @extend %u-text--nav;

      & > a{
        display:inline-block;
        border:none!important;
        transition:color 0.6s ease;
      }
 
      ul{
        display:none;
      }

      .is-active{
        color:$rtm-green;
      }

  }

  

  a{
    border:none;

    .Html--no-touch &{
      &:hover{
        color:$rtm-green;
      }
    }
    
  }
}


.Nav--sitemap{

  display:inline;

  .Nav-title{
    display:inline-block;
    font-weight: bold;

    @extend %u-text--nav;
    @include responsive-x(1,'padding-right',$base-vw-gutter);
  }

}
.List--nav.List--sitemap{
  display: flex;
  flex-direction: column;

  & > li{

      display:inline-block;
      @include responsive-x(1,'padding-right',$base-vw-gutter);

      &:after{

        content:'/';
        display: none;
        @include responsive-x(1,'padding-left',$base-vw-gutter);
      }

      &:last-child{
        &:after{
          display:none;
        }
      }

      @extend %u-text--nav;
  }

}



.List--nav.List--header{
  @include responsive('padding-right',$base-interface-height);
  @include responsive('height',$base-header-height);
  @include responsive('line-height',$base-interface-height);

  & > li{
    display:inline-block;
    padding-left:20px;
    padding-top: 15px;

    & > a{

        @extend %u-text--body;
        text-transform:uppercase;
        letter-spacing:0;

        &.is-active{

          color:$rtm-green;

        }
    }

  }

}

.List--nav.List--shortcuts{

  & > li{

    & > a{

        @extend %u-text--smallcaps;

    }

  }

}
.List--categories{

  li{

      display:inline-block;
      margin:0 10px;


      @include respond-to('mobile'){
        margin:0 5px;
      }

  }

  .is-active{
    color:$brick;
  }

  a{
    @extend %u-text--bigcaps;
    border-bottom:1px solid transparent;

    &:hover{
      border-bottom:1px solid;
    }
  }
}
.List--inline{
  li{

      display:inline-block;
      margin:0 10px;


      @include respond-to('mobile'){
        margin:0 5px;
      }

  }

  .is-active{
    color:white;
  }

  a{
    border-bottom:1px solid transparent;

    &:hover{
      border-bottom:1px solid;
    }
  }
}
.List--icons{
  height:$base-icon-size;
  li{
      a{
        display:block;
        height:$base-icon-size;
        line-height:$base-icon-size;
        overflow:visible;

      }
      display:inline-block;
      margin:0 0 0 10px;

      &:first-child{
          margin-left:0;
        }
     
        .Icon,.Icon:before{
          overflow:visible;
        }
  }
}
.List--grid{

    margin-left:-1px;

    .List-item{
      display:inline-block;
      width:100%;
      padding-left:1px;
      padding-bottom:1px;
      vertical-align:top;
    }

    &.List--half{

      .List-item{
        width:1/2 * 100%;
      }

    }

    &.List--onethird{

      .List-item{
        width:1/3 * 100%;
        @include respond-to('mobile'){
          width:50%;
          display:block;
          margin:0 auto;
        }
      }

    }

    &.List--onequarter{

      .List-item{
        width:1/4 * 100%;
        @include respond-to('mobile'){
          width:50%;
        }
      }

    }

    &.List--onefifth{

      .List-item{
        width:1/5 * 100%;
        @include respond-to('mobile'){
          width:50%;
        }
      }

    }

    &.List--full{
      .Pagination--lastPage{
        .Pagination-more{
          @include responsive-x(2,'height',$base-interface-height);
          padding:0;
        }
      }
    }

    &.List--style-feature{
      .List-item{
        display:block;
        margin-left:auto;
        margin-right:auto;
      }
      &.List--onethird{
        .List-item{
          width:50%;
          @include respond-to('mobile'){
            width:100%;
          }
        }
      }
      &.List--half{
        .List-item{
          width:2/3 * 100%;
          @include respond-to('mobile'){
            width:100%;
          }
        }
      }

    }

    
}
.List--tags{
  li{
    display:inline-block;
    margin-right:5px;
    @extend %u-text--small;
    span,a{

      display:inline-block;
      padding:0.2em;
      line-height:1;

      border:1px solid;
        color:inherit;
        background:transparent;

      

      transition:border 0.4s ease, color 0.4s ease,opacity 0.4s ease;
      border-radius:4px;
      &:hover{
        opacity:0.6;
      }
      &.is-active{
        background:$base-background;
        color:$base-color;
        border:1px solid transparent;
        opacity:1!important;
      }

    }
  }
}

.List--legal{
    
    a{
      @extend %u-text--small;
    }
    li{
      display:inline-block;
      padding-left:10px;
    }

}
