

%u-text--body{

	@include font-size($base-font-size);

	@include respond-to('mobile'){
		@include font-size(14px);
	}

	@include respond-to('wide'){
		@include font-size(18px);
	}

}

%u-text--caption{

	@include font-size(12px);

	@include respond-to('mobile'){
		@include font-size(12px);
	}

	@include respond-to('wide'){
		@include font-size(14px);
	}

}

%u-text--heading{
	

	font-size:3.5vw;
	line-height:1.1;

	@include respond-to('mobile'){
		font-size:6.5vw;
	}



}

%u-text--headingBold{
	
	@extend %u-text--heading;
	text-transform:uppercase;
	font-family:'RTM';
	font-weight: bold;

}

%u-text--bold{
	font-family:'RTM';
	font-weight: bold;
}

%u-text--subheading{
	font-size:2vw;
	line-height:1.1;

	@include respond-to('mobile'){
		font-size:4.5vw;
	}

}

%u-text--intro{
	
	font-size:10vh;
	white-space:nowrap;
	line-height:1;

	

	@include respond-to('mobile'){
		font-size:6vh;
	}

}




%u-text--bigcaps{
	text-transform:uppercase;
	@include font-size(20px);
	line-height:1.4;
letter-spacing:0.1em;
	@include respond-to('mobile'){
		@include font-size(18px);
	}
}

%u-text--nav{
	
	text-transform:uppercase;
	//@include font-size(20px);
	font-family:'RTM';
	font-weight: bold;
	font-size: 16px;
	line-height:1;
	font-weight:300;
	@include respond-to('mobile'){
		//@include font-size(18px);
	}
}

%u-text--smallcaps{
	text-transform:uppercase;
	font-family:'RTM';
	font-size:12px;
	font-weight: bold;

	letter-spacing:0.05em;


		@include respond-to('mobile'){
		@include font-size(14px);
		}
}

%u-ticker--small {
	font-family:'RTM';
	font-size: 16px;
	font-weight: bold;

	letter-spacing:0.05em;


		@include respond-to('mobile'){
			@include font-size(12px);
		}
}

%u-text--lightcaps{
	text-transform:uppercase;
	font-family:$base-font-family;
	font-weight:300;
	font-size:12px;
	font-style:italic;
	

		@include respond-to('mobile'){
		@include font-size(14px);
		}
}

%u-ticker--light {
	font-family:$base-font-family;
	font-weight:300;
	font-size: 16px;
	

		@include respond-to('mobile'){
		@include font-size(14px);
		}
}

%u-text--small{
	
		font-size:10px;
		font-family:$base-font-family;
		font-weight:300;
	
	

		@include respond-to('mobile'){
		@include font-size(8px);
		}
}