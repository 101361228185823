@keyframes fadeIn{

	0%{
		opacity:0;
	}

	100%{
		opacity:1;
	}

}
@keyframes fadeOut{

	0%{
		opacity:1;
	}

	100%{
		opacity:0;
	}

}
.Intro{
	cursor:wait;
	position:fixed;
	left:0;
	width:100%;
	z-index:9;
	
	text-align:center;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:black;
	color:white;
	opacity:0;

	animation:fadeIn 0.6s ease forwards;

	.Html--loaded &{
		pointer-events:all;
		cursor:pointer;
	}
	.Html--open &{
		pointer-events:none;
		opacity:1;
		animation:fadeOut 0.6s ease forwards;
	}

	.Gallery-body > div{

		pointer-events:none!important;
	}
}

.Intro-logo{
	span{
		display:none;
	}
	position:absolute;
	top:50%;
	transform:translate(0%,-50%);
	left:10%;
	width:80%;
	//max-width:600px;
	margin: 0 auto;
	opacity:0;

	svg path,
	svg polygon,
	svg rect{
		fill:white!important;
	}

	.Html--loaded &{
	
		animation:fadeIn 1s 0.5s ease forwards;

	}

}

.Intro-quote{
	opacity:1;
	position:absolute;
	top:50%;
	transform:translate(-50%,-50%);
	left:50%;
	width:100%;
	height:17vw;
	line-height:17vw;
	
	.Html--loaded &{
		animation:fadeOut 0.6s 0s ease forwards;
	}
	@extend %u-text--intro;
	text-align:center;

	*{
		pointer-events:none!important;
	}
}