.Template{
	
}
.Template-head{
	display:none;

}

.Template--search,.Template--listing,.Template--basic,.Template--error{
	@include responsive-x(2,'padding-top',$base-interface-height);
	color:white;
	&:after{
		content:'';
		display:table;
	}

}

.Template--error{
	@include responsive-x(3,'padding-top',$base-interface-height);
	@include responsive-x(1,'padding-left',$base-padding);
	@include responsive-x(1,'padding-right',$base-padding);
}

.Template--listing{
	@include responsive-x(1,'padding-top',$base-interface-height);

	.Template-head{
		display:block;
		@include responsive-x(1,'padding',$base-padding);
	}
	.Template-tags{
		@include responsive-x(1,'padding-left',$base-padding);
		@include responsive-x(1,'padding-right',$base-padding);
		@include responsive-x(0.5,'padding-bottom',$base-padding);

		a{
			opacity:0.5;
			.Html--no-touch &{
				&:hover{
					opacity:1;
				}
			}
		}
	}
	.Template-content{
		@include responsive-x(1,'padding-left',$base-padding);
		@include responsive-x(1,'padding-right',$base-padding);
		@include responsive-x(1,'padding-bottom',$base-padding);

		p:first-child{
			@extend %u-text--subheading;
		}
	}
	.Template-intro,
	.Template-blocks{
		@include responsive-x(1,'padding-top',$base-interface-height);
		display:inline-block;
		width:100%;
		vertical-align:top;
		min-height:100vh;
		@include respond-to('desktop'){
			width:50%;
		}
	}

	@include respond-to('tablet-down'){
		.Template-intro{
			min-height:0;
		}

		.Template-blocks{
			min-height:70vh;
			.Blocks-section:first-child{
				min-height:70vh;
			}
		}
	}

	.Template-blocks{
		background:#111;
		position:relative;
		.Block-coverflow{
			position:absolute;
			top:50%;
			left:0;
			width:100%;
			transform:translateY(-50%);
			@include responsive-x(-1,'margin-top',$base-interface-height);
		}
	}

}
