.Link{
	cursor:pointer;
}
.Link--more{
	cursor:pointer;
	@extend %u-text--body;
	@include responsive('padding-top',$base-grid-gutter);
	line-height:120%;
}


.Pagination-more .Link--more,
.Link--button{
	@extend %u-link--button;
	&.Link--solid{
	 	@extend %u-link--buttonSolid;

	 	.Playbutton.is-active &{
	 		color:white;
	 		background:$rtm-green;
	 		border-color:$rtm-green;
	 	}
	 }
}

.Pagination-more{
	text-align:center;
	@include responsive-x(2,'padding',$base-interface-height);
}
.Pagination-more .Link--more{

	transform:scale(2);

}

.Link--play{
	min-width:150px;
	color:$rtm-green;
	text-align:center;
	
	&:before{
		@extend %u-iconfont;
		@extend %u-iconfont--play;
		display:inline-block;
		margin-right:5px;
	}

	.Playbutton.is-active &{
	 		color:white;
	 		background:$rtm-green;
	 		border-color:$rtm-green;

	 		.Link-body{
	 			display:none;

	 		}

	 		&:after{
	 			content:'Playing'
	 		}
	 	}

}

