.Content{

	pre{
		background:rgba(255,255,255,0.1);
		border-radius:5px;
		padding:10px;
		opacity:0.8;
	}

	h1,h2{
		@extend %u-text--heading;
		padding-bottom:2rem;
		padding-top:1rem;

		&:first-child{
			padding-top:0;
		}

	
	}

	h3{
		@extend %u-text--subheading;
		padding-bottom:2rem;
		padding-top:1rem;

		
	}
	a{
		border-bottom:0.15em solid;
		opacity:1;
		transition:opacity 0.2s ease, border 0.6s ease;
	
			color:inherit;
		
		.Html--no-touch &{
			&:hover{
				
				opacity:0.65;
			}
		}
	}
	h4{
		@extend %u-text--body;
		@extend %u-text--bold;
		padding-bottom:1rem;
		padding-top:1rem;
		
		a{
			border:1px solid;
			padding:0.1em 0.5em 0;
			display:inline-block;
			transition:opacity 0.4s ease;
			&:hover{
				border:1px solid!important;

			}
		}
	}

	h5,h6{
		@extend %u-text--caption;
		padding-bottom:1rem;
		padding-top:1rem;
	}



	p,ul,ol{
		@extend %u-text--body;
		padding-bottom:1rem;

		
	}

	h4,h3,p,ul,ol{
		max-width:600px;
	}

	ol,ul{
		padding-top:2em;
		margin-left:2em;
		padding-bottom:2em;
		
		ol,ul{
			margin-bottom:0;
			padding-bottom:0;
		}
	}
	ol li{
		list-style-type:decimal-leading-zero;
		padding-bottom:0.2em;
		ol,ul{
			padding-top:0.2em;
		}
		li{
			padding-bottom:0.1em;
		}
		ol li{

			list-style-type:lower-roman;

		}
		ul li{

			list-style-type:circle;

		}
	}
	ul li{
		list-style-type:disc;
		padding-bottom:0.2em;
		ol,ul{
			padding-top:0.2em;
		}
		li{
			padding-bottom:0.1em;
		}
		ul li{

			list-style-type:circle;
			

		}
	}

	strong{
		@extend %u-text--bold;
	}

	p:last-child,ul:last-child,ol:last-child{
		padding-bottom:0;
		margin-bottom:0;
	}
}

.Site-panel{
	.Content{

		h1,h2,h3,h4,h5,h6,& > *{
			@extend %u-text--body;
			line-height:1.3;
			padding-bottom:1em;
			padding-top:0;
		}
	}

	.Block--play + .Block--text{
		.Block-content > .Content:first-child{
			padding-top:0;
		}
	}

	.Block--align-centre{
		.Content{
			text-align: center!important;
		}
	}
}

.Content.Content--project{
	h1{
		@extend %u-text--bigcaps;
		padding-bottom:0;
	}

	h2,h3{
		@extend %u-text--body;
		padding-bottom:0;
	}

	h3{
		opacity:0.6;
	}
	@include responsive('padding-bottom',$base-padding);
}

.Content.Content--article{
	h1,h2,h3,h4{
		@extend %u-text--body;	
		padding-bottom:0;
		text-align:left;
	}
}

.Content--credit{
	
	p{
		@extend %u-text--small;
		padding-bottom:0;
	}

	a{
		border-bottom:1px solid;
	}
}