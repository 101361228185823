.Form{
	
	max-width:400px;
	position:relative;
	padding-right:30px;

	@include respond-to('mobile'){
			//max-width:300px;
		}

	.Form-field *{
		@extend %u-text--small;
	}
	.Form-field input,
	.Form-field textarea{
		background:transparent;
		border:1px solid $base-color;
		padding:8px 5px;
		display:block;
		width:100%;
		
		&::placeholder{
			color:$base-placeholder-color;
			opacity:$base-placeholder-opacity;
		}
	}

	.Form-field textarea{

		resize: none;
		height:7em;
	}

	.Form-field{
		padding-bottom:3px;
	}
	.Form-field-title{
		padding-bottom:10px;

		/*@include respond-to('tablet-up'){
			position:absolute;
			left:100%;
			bottom:0;
			width:100%;
			padding:10px;
			padding-left:30px;
		}*/
	}
	.Form-submit{
		display:block;
		@include responsive('padding-top',$base-grid-gutter);
		
		cursor:pointer;
	

		text-align:right;

		button{
			
			display:inline-block;
			border:1px solid;
			text-align:center;
			min-width:90px;
			padding:0.1em 0.4em 0;

		}
		
	}

}