.Item-dot{
	position:relative;
	display:inline-block;
	@include responsive('width',$base-interface-height);
	@include responsive('height',$base-interface-height);


	&:before{

		content:'•';
		@include responsive-x(1.2,'font-size',$base-interface-height);
		@include responsive-x(1.2,'line-height',$base-interface-height);
		@include responsive('height',$base-interface-height);
		display:inline-block;
		top:0%;
		left:50%;
		
		position:absolute;
		transform:translate(-50%,0%);

	}
}

.Item-media{
	position:relative;
	display:block;
}
.Item-media--play{
	cursor:pointer;
	
}

a.Item-media{

	.Html--no-touch &{
		&:hover{
			opacity:1;
		}
	}

}

.Item-media-play{
	background:$base-color;
	color:$base-background;
	display:block;
	position:absolute;
	bottom:0;
	left:0;
	z-index:5;
	cursor: pointer;
	width:60px;
	height:60px;
	padding:5px;

	
	.Playbutton.is-active &{
		background:$rtm-green;
	}
	.Icon{
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
}

.Item--coverflow{
	.Playbutton{
		position:absolute;
		bottom:0;
		left:0;
		
		.Item-media-play{
			position:relative;
		}
	}
}

.Item--audio,
.Item--listing{
	position:relative;

	.Item-media{
		width:80px;
		position:absolute;
		top:0;
		left:0;

		.Image{
			padding-top:100%!important;
			background:#333;
		}

	}

	.Item-media-play{
		width:30px;
		height:30px;

		.Icon{
			width:30px;
			height:30px;
			&:before{
				font-size:14px!important;
				position:absolute;
				top:50%;
				left:50%;
				width:auto;
				transform:translate(-50%,-50%);
			}
		}
	}

	.Item-content-wrap{
		
		padding-left:90px;
		padding-top:10px;
		min-height:80px;
	}
}

.Item--player{

	.Item-content{

		position:absolute;
		top:0;
		left:0;
		bottom:0;
		@include responsive-x(1,'right',$base-interface-height);	

	}

	.Item-close{
		position:absolute;
		top:0;
		left:auto;
		bottom:0;
		right:0;
		@include responsive-x(1,'width',$base-interface-height);
		border-left:1px solid rgba(0,0,0,0.2);

		.Link{
			opacity:0.2;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			transition:opacity 0.6s ease;

			.Html--no-touch &{

				&:hover{
					opacity:1;
				}
			}
		}
		.Icon{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%) rotate(90deg);
			font-size:12px;
			line-height:1;
			width:auto;
			height:auto;
		}
	}

}

.Item--live,
.Item--loader{

	@include responsive('height',$base-interface-height);
	
	@include responsive-x(1.1,'line-height',$base-interface-height);
	
	cursor:pointer;

	.Item-dot{

		animation:pulse 1s ease infinite;
		position:absolute;
		top:0;
		left:0;

	}

	.Item-head{
		@include responsive('padding-left',$base-interface-height);
		@include responsive('height',$base-interface-height);
		@extend %u-text--smallcaps;
		@include responsive-x(1.1,'line-height',$base-interface-height);
		text-align:left;
		width:29%;
		position:relative;
		z-index:2;
		
		.Icon{
			position:absolute;
			top:50%;
			right:-5px;
			transform:translate(0,-55%)
		}
	}

	.Item-body{
		position:absolute;
		top:0;
		left:29%;
		
		&:after{
			content:'';
			width:20%;
			height:100%;
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			display:block;
			top:0;
			left:-1px;
			position:absolute;
		}
		
		right:0;
		bottom:0;
		overflow:hidden;
		.Item-text{
			display:inline-block;

			@include responsive-x(1,'padding-left',$base-padding);
			@extend %u-text--smallcaps;
			&:first-child{
				@extend %u-text--lightcaps;
				

			}
		}
	}

}

.Item--live{
	.Item-dot{
		color:red;
	}
}

.Item--loader{
	.Item-head{
		//@extend %u-text--lightcaps;
		//font-style:normal;
		width:auto;
	}
}


.Item--radio{
	@include responsive-x(1,'height',$base-player-height);

	.radioplayer{
		border-radius:0;
		height:100%;
		overflow:visible;
		color:black;
		padding:0;
		background:#FFF;
		@include responsive-x(2,'padding-left',$base-player-height);

		.radioco-nowPlaying,
		.radioco-elapsedTime{

			@extend %u-text--smallcaps;

		}

		&:before{
			content:'';
			width:100px;
			height:100%;
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
			display:block;
			top:0;
			right:90px;
			position:absolute;
			z-index:2;
			
		}

		&:after{
			@extend %u-iconfont;
			@extend %u-iconfont--volume;
			position:absolute;
			right:100px;
			top:50%;
			transform:translate(0,-50%);
			opacity:0.2;
			z-index:3;
		}

		.radioco-volume{

			position:absolute;
			top:50%;
			width:80px;
			transform:translate(0,-50%);
			@include responsive-x(0.5,'right',$base-interface-height);
			margin:0;
			z-index:3;
		}

		.radioco-nowPlaying{

			position:absolute;
			top:50%;
			transform:translate(0,-50%);
			line-height:1;
			@include responsive-x(2,'left',$base-player-height);
			z-index:1;
			right:100px;
			white-space:nowrap;
			overflow:hidden;
		
		}

		.radioco-image,
		.radioco-playButton{

			display:block;
			box-shadow:0 0 2px rgba(0,0,0,0.5);
			@include responsive-x(1.5,'height',$base-player-height);
			@include responsive-x(1.5,'width',$base-player-height);
			transform:none;
			bottom:0;
			@include responsive-x(0.5,'left',$base-interface-height);
			top:auto;
		}
		.radioco-image{
			background:$rtm-green;
		}
		.radioco-playButton{

			& > div{
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
			}
			
		
		}
	}



}

.Item--coverflow,
.Item--galleryLink,
.Item--default,
.Item--feature{

	display:block;
	text-align:center;
	*{

		pointer-events:none;
		
	}


	.Item-tags{
		@include responsive-x(0.5,'padding-top',$base-grid-gutter);
		
		a{
			opacity:0.5;

			.Html--no-touch &{
				&:hover{
					opacity:1;
				}
			}
		}
		
		overflow:hidden;
		li{
			display:none;
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4){
				display:inline-block;
			}
		}


	}
	.Item-content{
		
		
		@include responsive('padding-top',$base-grid-gutter);
		transition:opacity 0.8s ease;

		opacity:0;

		.swiper-slide-active &{
			opacity:1;
		}
	}

	.Item-media{
		z-index:1;
	}
	.Item-media .Image{

		padding-top:100%!important;

	}

	.Item-title,.Item-subtitle{
		@extend %u-text--body;
		line-height:120%;
		font-size: 8px;
	}

	.Item-subtitle{
		opacity:0.6;
	}

	.Item-link{
		@include responsive('padding-top',$base-grid-gutter);
		
		text-transform:uppercase;
		a{
			color:inherit;
			@extend %u-link--button;
			
			box-sizing:border-box;
		}

	}

}
.Item--coverflow{
	
	.Item-media{
		background:#222;
	}
	.Item-media > a{
		display:block;
	}
	.Image-media{
		&.swiper-lazy{
			opacity:0;
		}
		
		transition:opacity 0.6s 0.1s ease;
		
		&.swiper-lazy-loaded{
			opacity:1;
		}
	}	

}
.Item--default,
.Item--galleryLink,
.Item--feature{
	.Item-content{
		opacity:1;
		@include responsive('padding-bottom',$base-interface-height);
	}
	*{
		pointer-events:all;
	}
}

.Item--feature{
	
	.Item-body{
		padding-top:20px;
		width:90%;
		margin-left:auto;
		margin-right:auto;
		max-width:400px;
	}

}

.Item--default{
	.Item-title *,
	.Item-subtitle *{
		@extend %u-text--body;
	}
	.Item-body{
		padding-top:10px;
		width:90%;
		margin-left:auto;
		margin-right:auto;
	}
	.Item-body .Content *{
		@extend %u-text--caption;
		//text-align:center!important;
	}
}

.Item-content-play{

	display:none;
}

.List-item--full .Item--default,
.Block--swiper .Item--galleryLink{

		.Item-body{
			margin-left:0;
			.Content *{
				text-align:left!important;
			}
		}

}

.List-item--full > .Item--default,
.Item--galleryLink{

	.Item-body{
		padding-top:5px;
	}
	.Item-body .Content,
	.Item-body .Content *{
		@extend %u-text--body;
		font-weight:300;
	}
	position:relative;
	.Item-media-play{
		/*position:sticky;
		bottom:120px;
		*/
		bottom:auto;
		position:relative;

	}
	
	.Item-media .Item-media-play{
		display:none;
	}

	.Item-content-play{

		display:block;
	}


	.Item-content-wrap{
		position:absolute;
		position:sticky;
		left:0;
		@include responsive-x(1,'bottom',$base-interface-height);
		//bottom:0;
		z-index:1;
		width:100%;
		
		
	}
	.Item-content{
		/*position:absolute;
		bottom:0;
		left:0;*/
		text-align:left;
		background:black;
		padding:20px;
		@include responsive('padding-left',$base-padding);
		//padding-bottom:0;
		bottom:0px;
		width:20%;
		min-width:400px;

		@include respond-to('mobile'){
			min-width:0;
			width:80%;
		}
		@include responsive-x(3.5,'min-height',$base-interface-height);

	}


}

.List-item--full > .Item--default{
	background:black;
	*{
		color:white;
	}
}

.Item--galleryLink{

	opacity:1!important;

	.Item-media, & > .Playbutton{
		position:absolute!important;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}

	.Item-media-play{
		cursor:pointer!important;
		pointer-events:all;
	}

	.Item-media .Image{
		position:absolute;
		width:100%;
		padding-top:0!important;
		height:100%;
	}

	a,.Playbutton{
		pointer-events:all;
	}
	.Item-content{
		opacity: 1!important;
	}
	.Item-content-wrap{
		position:absolute;
		bottom:0!important;
		*{
			color:white;
		}
	}
}

.Item--fullscreen{
	height:calc(100vh - 60px)!important;
	.Item-media .Image{
		//padding-top:calc(100vh - 60px)!important;
	}

}



.Item--feature{

	@include respond-to('mobile'){
		display:block;
		width:60%;
		margin:0 auto;
	}

	
	.Item-title h1{
		@extend %u-text--subheading;
	}

	.Item-link a{
		@extend %u-link--button;
	}

	& > *{

		display:inline-block;
		vertical-align:middle;
		width:100%;
		@include respond-to('tablet-up'){
			width:50%;
		}
		
	}

	.List-item:nth-child(even) &{
		direction: rtl;
		& > *{
			direction:ltr;
		}
	}

}


/*




















*/









.Item--gallery{
	opacity:1!important;
	position:relative;
	.Item-body{
		position:absolute;
		z-index:1;
	
		left:0;
		right:0;
		bottom:0;
		
		.Content *{
			
			@extend %u-text--caption;
		}
		opacity:1;
		text-align:left;
		color:white;
		
		@include responsive-x(1,'padding-left',$base-padding);
		@include responsive-x(2,'padding-right',$base-padding);

		&:after{
			content:'';
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			@include responsive-x(2,'height',$base-interface-height);
			opacity:0.8;
			width:100%;
			z-index:1;
		}	
		.Content{
			position:relative;
			z-index:2;
			text-align:left!important;
			padding-bottom:1rem;
		}
	}

	.Item-media{
		z-index:0;
	}

	&:hover{
		.Item-body{
			opacity:1;
		}
	}

}

.Item--swiper{

	width:100vw;
	height:80vh;
	min-height:400px;
	position:relative;
	
	

	.Item-media{
		
		position:static;

	}

	

}

.Item--thumb{
	position:relative;

	.Item-media{
		@include pad-ratio(6,4);
	}
}

.Item--noresults{
	padding:20vh 0;
	text-align:center;
	@extend %u-text--smallcaps;
}


.Item--post{

	display:block;

	.Item-media{
		height:33vw;
		background:$brick;
		position:relative;
	}

	.Item-body{

		
		@include responsive('padding-top',$base-grid-gutter);
	
		
	}

	.Item-title,.Item-subtitle{
		@extend %u-text--body;
		line-height:120%;
	}

	.Item-subtitle{
		opacity:0.6;
		@include responsive('padding-bottom',$base-grid-gutter);
	}

	.Item-date{
		@extend %u-text--smallcaps;
	}
	.Item-summary{
		width:80%;
		max-width:300px;
		@include respond-to('mobile'){
			display:none;
		}
	}

}

