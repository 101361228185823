@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

.radioco-playButton-playing:before{
		font-family: 'FontAwesome', sans-serif;
	  content: "\f04b";
	}

	.radioco-playButton-paused:before{
		font-family: 'FontAwesome', sans-serif;
	  content:"\f04c";
	}

	.radioplayer{
	  background: #F4F4F4;
	  padding: 20px;
	  width: 100%;
	  height: 50px;
	  
	  border-radius:4px;
	  background-position:center center !important;
	  background-repeat:no-repeat !important;
	  background-size: cover !important;
	  overflow: hidden;

	  -webkit-touch-callout: none;
	  -webkit-user-select: none;
	  -khtml-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}



	.radioco-playButton{
	  box-shadow: #111 1px 1px 10px;
	  color: #fff;
	  position: relative;
	  top: 50%;
	  text-align: left;
	  left:10px;
	  transform:translate(0,-50%);
	  position: absolute;
	  z-index: 2;
	  font-size:50px;
	}

	.radioco-playButton:active{
	  color:#ccc;
	}

	.radioco-playButton-playing{
	  font-family: "controls";
	  position: absolute;
	  text-size:150%;
	  text-shadow:#333 0px 0px 15px;
	}


	.radioco-playButton-paused{
	  font-family: "controls";
	  position: absolute;
	  text-size:150%;
	  text-shadow:#333 0px 0px 15px;
	}


	.radioco-image{
	  display:block;
	  position:absolute;
	  top:0;
	  left:0;
	  width:50px;
	  height:50px;
	}

	.radioco-bg{
	  display:none;
	}

	.radioco-information{
	  display: inline-block;
	  

	}

	.radioco-information span{
	  display:inline-block;
	}

	.radioco-information input[type="range"]{
	    background: rgba(0,0,0,0.3);
	  width: 100px;
	  display: inline-block;
	  height: 1px;
	  -webkit-appearance: none;
	  -webkit-border-radius: 0px;
	  margin-top: 20px;
	  margin-bottom: 20px;
	  cursor: -webkit-grab;
	  outline: 0;


	   }



	.radioco-information input[type=range]::-webkit-slider-thumb {
	  -webkit-appearance: none;
	  border: none;
	  width:15px;
	  height:15px;
	  border-radius: 10px;
	  background: black;
	  -webkit-transition: box-shadow 0.2s;

	}
	.radioco-information input[type=range]::-webkit-slider-thumb:hover {
	  /*box-shadow:#000 1px 1px 20px;*/
	  background:#333;
	}

	.radioco-information input[type=range]:active, .information input[type=range]:focus {
	  outline: 0;
	}

	.radioco-elapsedTime{
	  opacity:0.4;
	  font-size:14px;
	}